.bar-container {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  position: relative;
}
.bar-container .hide {
  display: none;
}
