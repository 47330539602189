.page {
  position: relative;
  width: 100vw;
  min-height: 100%;
  height: auto;
  &.in-from-left {
    animation: in-from-left-ani 0.5s ease-in-out running infinite;
  }
  &.out-to-right {
    animation: out-to-right-ani 0.5s ease-in-out running infinite;
  }
  @keyframes in-from-left-ani {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0%);
    }
  }
  @keyframes out-to-right-ani {
    from {
      transform: translateX(0%);
    }
    to {
      transform: translateX(100%);
    }
  }
}

.white-icon {
  filter: invert(100%)
}
